var voltarAoTopo = (function(){
	var btn  = $('#voltar-topo');
	var time = btn.data('time') || 1000;
	var _ja  = false;

	btn.click(function(e){
		e.preventDefault();

		$('body, html').animate({
			scrollTop: 0
		}, time);

		btn.blur();
	});

	$(window).scroll(function(){
		if(_ja) return;

		setTimeout(function() {
			_ja = false;
		}, (1000/60));

		verify();
	});

	$(window).trigger('scroll');

	function verify () {
		if($(window).scrollTop() >= (window.innerHeight / 2)) {
			btn.addClass('active');
		}else{
			btn.removeClass('active');
		}
	}
})();